import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UsersService {
  url: string;

  constructor(private http: HttpClient, private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal/users`;
  }

  upsertUser(params: any): Observable<any> {
    if (params.authId) {
      return this.http.put<any>(
        `${this.url}/${params.authId}`,
        params,
        this.apiService.requestOptions()
      );
    }
    return this.http.post<any>(
      `${this.url}`,
      params,
      this.apiService.requestOptions()
    );
  }

  getUsers(params: any): Observable<any> {
    const page = params.page ? params.page : 1;
    const pageSize = params.pageSize
      ? params.pagSize
      : environment.defaultPageSize;
    return this.http.get(
      this.url,
      this.apiService.requestOptions({ page, pageSize, ...params })
    );
  }

  getUser(id: number): Observable<any> {
    return this.http.get(`${this.url}/${id}`, this.apiService.requestOptions());
  }

  deleteUser(id: number): Observable<any> {
    return this.http.delete(
      `${this.url}/${id}`,
      this.apiService.requestOptions()
    );
  }
}
