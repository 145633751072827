import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GeneralService } from '../../modules/private/services/general.service';

@Component({
  selector: 'ptc-approval-widget',
  templateUrl: './approval-widget.component.html',
  styleUrls: ['./approval-widget.component.scss']
})
export class ApprovalWidgetComponent implements OnChanges {
  @Input() data: Array<any>;
  @Input() isSingleCompany: boolean;
  @Input() selectedCompanies: Array<number>;
  @Output() searchText: EventEmitter<string>;
  @Output() filterCompany: EventEmitter<number>;
  filterData: Array<any>;
  companyFilter: Array<any>;

  constructor(
    private generalService: GeneralService

  ) {
    this.searchText = new EventEmitter<string>();
    this.filterCompany = new EventEmitter<number>();
    this.companyFilter = [];

    this.filterData = [
      {
        type: 'input-search',
        placeholder: 'Buscar',
        col: 12,
        col_md: 6
      }
    ];
    this.setCompanyList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedCompanies) {
      this.filterData.unshift({
        type: 'select',
        label: 'a empresa',
        key: 'companyId',
        data: this.companyFilter,
        col: 6,
        col_md: 6
      });
      this.filterCompanies();
    }
  }

  setCompanyList() {
    this.generalService.getLists('companies').subscribe(
      response => {
        this.companyFilter = response;
        this.filterData[0].data = this.companyFilter;
        this.filterCompanies();
      });
  }

  filterCompanies() {
    if (!this.isSingleCompany) {
      const _filteredCompanies = [];
      this.selectedCompanies.map(element => {
        const _companies = this.companyFilter.filter(item => item['key'].toString() === element);
        _companies.map(item => _filteredCompanies.push(item));
      });
      this.filterData[0].data = _filteredCompanies;
    }
  }

  search(event) {
    this.searchText.emit(event);
  }

  select(event: any) {
    this.filterCompany.emit(event.value);
  }

}
