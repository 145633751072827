import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LaddaModule } from "angular2-ladda";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrModule } from "ngx-toastr";
import { TextMaskModule } from "angular2-text-mask";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { Select2Module } from "ng2-select2";
import { FileSaverModule } from "ngx-filesaver";
import { AppRoutingModule } from "./app-routing.module";
import { AppSharedModule } from "./shared/app-shared.module";
import { AppComponent } from "./components/start/app.component";
import { AuthGuard } from "./modules/public/services/auth-guard.service";
import { AuthService } from "./modules/public/services/auth.service";
import { ApiService } from "./services/api.service";
import { ToggleMenuService } from "./services/toggle-menu.service";
import { MaskService } from "./services/masks";
import { CepService } from "./services/cep.service";
import { FileUploadService } from "./modules/private/services/file-upload.service";
import { GeneralService } from "./modules/private/services/general.service";
import { UsersService } from "./modules/private/services/users.service";
import { PublicUsersService } from "./modules/private/services/public-users.service";
import { ManagerUsersService } from "./modules/private/services/manager-users.service";
import { JwtModule } from "@auth0/angular-jwt";
import { NgDatepickerModule } from "ng2-datepicker";
import { ImageCropperModule } from "ngx-image-cropper";
import { ModalModule } from "ngx-bootstrap/modal";

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    AppSharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    LaddaModule,
    ImageCropperModule,
    RouterModule,
    Ng2SmartTableModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    TextMaskModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    Select2Module,
    FileSaverModule,
    NgDatepickerModule,
    ModalModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    AuthService,
    ApiService,
    ToggleMenuService,
    MaskService,
    CepService,
    FileUploadService,
    GeneralService,
    UsersService,
    PublicUsersService,
    ManagerUsersService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
