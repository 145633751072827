import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  Output,
  Input,
  OnDestroy,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { FlowDirective } from "@flowjs/ngx-flow";
import { Subscription } from "rxjs";

@Component({
  selector: "ptc-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
})
export class ImageUploadComponent implements OnInit, OnDestroy {
  @Input() path: string;
  @Output() fileUploaded: EventEmitter<any>;
  @Input() previewImage: string;
  @Input() id: string;
  @Input() type: string;
  @Input() customSize: string;
  @Input() selfRender: boolean = true;
  flowConfig: any = {};
  url: string;
  isLoading: boolean;
  errorMessage: string;
  shortErrorMessage: string;
  modalIsOpen: boolean;

  @ViewChild("flow")
  flow: FlowDirective;

  autoUploadSubscription: Subscription;

  constructor() {
    this.url = `${environment.apiUrl}/v1`;
    this.fileUploaded = new EventEmitter<any>();
  }

  ngOnInit() {
    let _query = "";
    if (this.customSize){
      _query = `?size=${this.customSize}`;
    }
    this.flowConfig.target = `${this.url}${this.path}${_query}`;
    this.flowConfig.chunkSize = 20000000;
    this.flowConfig.singleFile = true;
    this.flowConfig.testChunks = false;
    this.flowConfig.headers = {
      Authorization: `Bearer ${localStorage.getItem("control-panel.token")}`,
    };

    this.autoUploadSubscription = this.flow.events$.subscribe((event) => {
      this.errorMessage = null;
      this.shortErrorMessage = null;
      switch (event.type) {
        case "filesSubmitted":
          this.isLoading = true;
          console.log(this.flow.flowJs);
          this.flow.upload();
          break;
        case "fileSuccess":
          const data = {
            file: event.event[1].replace(/\"/g, ""),
          };
          if (this.id) {
            data["id"] = this.id;
          }
          this.fileUploaded.emit(data);
          this.previewImage = event.event[1].replace(/\"/g, "");
          this.isLoading = false;
          break;
        case "fileError":
          this.isLoading = false;
          this.errorMessage = event.event[1].replace(/(?:\\[rn])+/g, "<br>");
          if (this.errorMessage.length > 50) {
            this.shortErrorMessage =
              this.errorMessage.slice(0, 50) + "... <b>[+]</b>";
          }
          break;
      }
    });
  }

  ngOnDestroy() {
    this.autoUploadSubscription.unsubscribe();
  }
}
