// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://private.api.procare.asteria.work',
  cdnPath: 'https://cdn.asteria.com.br/dev/protecon/',
  defaultPageSize: 20
};
