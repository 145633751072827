import { Component } from '@angular/core';

import { AuthService } from 'src/app/modules/public/services/auth.service';
import { ToggleMenuService } from 'src/app/services/toggle-menu.service';

@Component({
  selector: 'ptc-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  constructor(private authService: AuthService, public menu: ToggleMenuService) {}

  quit(): void {
    this.authService.logout();
  }
}
