import { Component, ViewChild, Input, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";

import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { ModalComponent } from "../modal/modal.component";
import { MaskService } from "../../services/masks";
import { CepService } from "../../services/cep.service";
import { GeneralService } from "../../modules/private/services/general.service";
import { PublicUsersService } from "../../modules/private/services/public-users.service";
import { ManagerUsersService } from "../../modules/private/services/manager-users.service";
import { AuthService } from "../../modules/public/services/auth.service";

@Component({
  selector: "ptc-users-register",
  templateUrl: "./users-register.component.html",
})
export class UsersRegisterComponent implements OnInit {
  @ViewChild("modal") modalComponent: ModalComponent;
  @Input() isManager: boolean;
  form: FormGroup;
  cepError: boolean;
  cepIsLoading: boolean;
  list: any;
  isLoading: boolean;
  authId: number;
  user: any;
  modalSubscription: Subscription;
  usersService: any;

  constructor(
    public location: Location,
    private formBuilder: FormBuilder,
    public mask: MaskService,
    private cepService: CepService,
    private generalService: GeneralService,
    private managerUsersService: ManagerUsersService,
    private publicUsersService: PublicUsersService,
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
    this.list = {};

    this.authId = this.activatedRoute.snapshot.params.authId;
    this.user = this.activatedRoute.snapshot.data.user;
  }

  ngOnInit(): void {
    this.getList("companies");
    this.getList("departments");

    this.usersService = this.isManager
      ? this.managerUsersService
      : this.publicUsersService;

    if (this.authId) {
      this.usersService.getUser(this.authId).subscribe((response) => {
        this.user = response;
        this.createEditForm();
      });
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      name: ["", Validators.required],
      gender: [""],
      birthdateOn: [""],
      email: ["", [Validators.required, Validators.email]],
      cpf: [""],
      rg: [""],
      phone1: [""],
      postalCode: [""],
      address: [""],
      addressNumber: [""],
      addressComplement: [""],
      neighborhood: [""],
      city: [""],
      state: [""],
      jobRole: [""],
      departmentId: [""],
      isManager: [false],
      companyId: ["", Validators.required],
      powerbiReportId: [""],
      powerbiProbemReportId: [""],
    });
  }

  private createEditForm(): void {
    this.form = this.formBuilder.group({
      name: [this.user.name ? this.user.name : "", Validators.required],
      gender: [this.user.gender ? this.user.gender : ""],
      birthdateOn: [this.user.birthdateOn ? this.user.birthdateOn : ""],
      email: [
        this.user.email ? this.user.email : "",
        [Validators.required, Validators.email],
      ],
      cpf: [this.user.cpf ? this.user.cpf : ""],
      rg: [this.user.rg ? this.user.rg : ""],
      phone1: [this.user.phone1 ? this.user.phone1 : ""],
      postalCode: [this.user.postalCode ? this.user.postalCode : ""],
      address: [this.user.address ? this.user.address : ""],
      addressNumber: [this.user.addressNumber ? this.user.addressNumber : ""],
      addressComplement: [
        this.user.addressComplement ? this.user.addressComplement : "",
      ],
      neighborhood: [this.user.neighborhood ? this.user.neighborhood : ""],
      city: [this.user.city ? this.user.city : ""],
      state: [this.user.state ? this.user.state : ""],
      jobRole: [this.user.jobRole ? this.user.jobRole : ""],
      departmentId: [this.user.departmentId ? this.user.departmentId : ""],
      isManager: [this.user.isManager ? this.user.isManager : false],
      companyId: [
        this.user.companyId || this.user.companyId === 0
          ? this.user.companyId
          : "",
        Validators.required,
      ],
      powerBIReportId: [
        this.user.powerBIReportId ? this.user.powerBIReportId : "",
      ],
      powerBIProbemReportId: [
        this.user.powerBIProbemReportId ? this.user.powerBIProbemReportId : "",
      ],
    });
  }

  private getList(type: string): void {
    this.generalService.getLists(type).subscribe(
      (response) => {
        this.list[type] = response;
      },
      (error) => console.info(error)
    );
  }

  inputCep() {
    this.cepError = false;
    const cep = this.form.get("postalCode").value.replace(/\D+/g, "");
    if (cep.length === 8) {
      this.cepIsLoading = true;

      this.form.get("address").reset();
      this.form.get("addressComplement").reset();
      this.form.get("neighborhood").reset();
      this.form.get("city").reset();
      this.form.get("state").reset();

      this.form.get("postalCode").setValue(cep);
      this.form.get("postalCode").disable();
      this.cepService.getAddress(cep).subscribe((response) => {
        if (response.erro) {
          this.cepError = true;
          this.cepIsLoading = false;
          this.form.get("postalCode").enable();
        } else {
          if (response.logradouro) {
            this.form.get("address").setValue(response.logradouro);
          }
          if (response.complemento) {
            this.form.get("addressComplement").setValue(response.complemento);
          }
          if (response.bairro) {
            this.form.get("neighborhood").setValue(response.bairro);
          }
          if (response.localidade) {
            this.form.get("city").setValue(response.localidade);
          }
          if (response.uf) {
            this.form.get("state").setValue(response.uf);
          }

          this.cepIsLoading = false;
          this.form.get("postalCode").enable();
        }
      });
    }
  }

  private getBackPage(): string {
    if (this.isManager) {
      return "managers";
    } else {
      return "users";
    }
  }

  send(): void {
    if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return;
    }

    this.form.get("isManager").setValue(this.isManager);

    if (this.form.get("cpf").value) {
      this.form
        .get("cpf")
        .setValue(this.form.get("cpf").value.replace(/\D+/g, ""));
    }

    const value = {
      ...this.form.value,
      enumLanguage: "ptBr",
    };

    if (this.authId) {
      value["authId"] = this.user.authId;
    }

    this.usersService.upsertUser(value).subscribe(
      (response) => {
        this.toastrService.success(
          `Usuário ${this.authId ? "atualizado" : "cadastrado"} com sucesso`
        );
        this.router.navigate(["/panel", "main", this.getBackPage()]);
      },
      (error) => {
        if (error.status === 403) {
          this.authService.logout();
        }
        this.toastrService.error(
          `Não foi possível ${
            this.authId ? "atualizar" : "cadastrar"
          } o usuário`
        );
        this.isLoading = false;
      }
    );
  }

  private markFormGroupTouched = (formGroup) => {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  };

  inactivate() {
    this.modalComponent.setMessage(
      `Tem certeza que deseja desativar o usuário ${this.user.name}?`
    );
    this.modalComponent.toggle();
    this.modalSubscription = this.modalComponent.response$.subscribe(
      (response) => {
        if (response) {
          this.usersService.deleteUser(this.user.authId).subscribe(
            () => {
              this.toastrService.success("Usuário desativado com sucesso");
              this.router.navigate(["/panel", "main", this.getBackPage()]);
            },
            (error) => this.toastrService.error(error.error)
          );
        } else {
          this.modalSubscription.unsubscribe();
        }
      }
    );
  }
}
