import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ptc-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss']
})
export class VideoContainerComponent implements OnInit {
  @Input() height: number;
  @Input() width: number;
  @Input() videoLink: string;
  play = false;
  watchLink: string;
  cover = '';

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const videoId = this.videoLink.split('/').pop();
    this.cover = `https://img.youtube.com/vi/${videoId}/0.jpg`;
    this.watchLink = `https://www.youtube.com/embed/${videoId}`;
  }

  start() {
    this.play = true;
  }
}
