import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  templateUrl: './link-view.component.html'
})
export class LinkViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

}
