import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class GeneralService {
  url: string;

  constructor(private http: HttpClient, private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1`;
  }

  getLists(type: string, params: any = {}): Observable<any> {
    return this.http.get(
      `${this.url}/internal/${type}/list/ids`,
      this.apiService.requestOptions(params)
    );
  }
}
