import { Component, HostListener, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'ptc-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements AfterViewInit, AfterViewChecked {
  menuData: Array<any>;
  menuHeight: number;
  @ViewChild('signature') signature: ElementRef;
  @ViewChild('user') user: ElementRef;

  constructor(private _changeDetectionRef: ChangeDetectorRef) {
    this.menuData = [
      {
        name: 'Principal',
        icon: 'star_rate',
        task: 'ControlPanelAccess',
        toggle: true,
        submenu: [
          // {
          //   name: 'Início',
          //   path: ['/panel', 'main', 'dashboard'],
          //   task: 'ManageDashboard',
          // },
          {
            name: 'Empresas',
            path: ['/panel', 'main', 'companies'],
            task: 'ManageCompanies',
          },
          {
            name: 'Gestores',
            path: ['/panel', 'main', 'managers'],
            task: 'ManageManagers',
          },
          {
            name: 'Usuários',
            path: ['/panel', 'main', 'users'],
            task: 'ManageUsers',
          },
          {
            name: 'Campanhas',
            path: ['/panel', 'main', 'campaigns'],
            task: 'ManageCampaigns',
          },
          {
            name: 'Informativos',
            path: ['/panel', 'main', 'informatives'],
            task: 'ManageInformatives',
          },
          {
            name: 'Redes Credenciadas',
            path: ['/panel', 'main', 'networks'],
            task: 'ManageNetworks',
          },
          {
            name: 'Apólices',
            path: ['/panel', 'main', 'policies'],
            task: 'ManagePolicies',
          },
          {
            name: 'Pedômetro',
            path: ['/panel', 'main', 'pedometers'],
            task: 'ManagePedometers',
          },
          {
            name: 'Movimentações',
            path: ['/panel', 'main', 'movements'],
            task: 'ManageBenefitMovements',
          },
          {
            name: 'Relatórios',
            path: ['/panel', 'main', 'reports'],
            task: 'ManageReports',
          },
        ],
      },
      {
        name: 'Gerenciamento',
        icon: 'settings',
        task: 'ControlPanelAccess',
        toggle: true,
        submenu: [
          {
            name: 'Administradores',
            path: ['/panel', 'management', 'users'],
            task: 'ManageUsers',
          },
          {
            name: 'Arquivos',
            path: ['/panel', 'management', 'downloads'],
            task: 'ManageDownloads',
          }
        ],
      },
    ];
  }

  @HostListener('window: resize', ['$event'])
  onresize($event) {
    this.setMenuHeight();
  }

  private setMenuHeight(): void {
    const h = window.innerHeight;
    const t = this.signature.nativeElement.offsetHeight;
    const u = this.user.nativeElement.offsetHeight;
    this.menuHeight = h - t - u;
  }

  ngAfterViewInit() {
    this.setMenuHeight();
  }

  ngAfterViewChecked(): void {
    this._changeDetectionRef.detectChanges();
  }

  toggleSubmenu(item: any): void {
    item.toggle = !item.toggle;
  }

}
