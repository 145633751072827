import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptc-page-header',
  templateUrl: './page-header.component.html'
})
export class PageHeaderComponent {
  @Input() title: string;
  @Input('adminInsertOnly') adminInsertOnly: boolean;
  @Input('insertUrl') insertUrl: string;
}
