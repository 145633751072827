import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptc-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() p: any;

  constructor() {}

}
