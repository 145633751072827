import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  Output,
  Input,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { FlowDirective } from "@flowjs/ngx-flow";
import { Observable, Subscription } from "rxjs";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "ptc-image-cropper-upload",
  templateUrl: "./image-cropper-upload.component.html",
  styleUrls: ["./image-cropper-upload.component.scss"],
})
export class ImageUploadCropperComponent implements OnInit, OnDestroy {
  @Input() path: string;
  @Output() fileUploaded: EventEmitter<any>;
  @Input() previewImage: string;
  @Input() id: string;
  @Input() type: string;
  @Input() customSize: string;
  flowConfig: any = {};
  url: string;
  isLoading: boolean;
  errorMessage: string;
  shortErrorMessage: string;
  modalIsOpen: boolean;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  showCropperModal: boolean = false;
  formData: FormData;

  @ViewChild("flow")
  flow: FlowDirective;

  @ViewChild("fileInput")
  fileInput: ElementRef;

  autoUploadSubscription: Subscription;

  constructor(private http: HttpClient, private toastrService: ToastrService) {
    this.url = `${environment.apiUrl}/v1`;
    this.fileUploaded = new EventEmitter<any>();
  }

  ngOnInit() {}

  ngOnDestroy() {
    /*  this.autoUploadSubscription.unsubscribe(); */
  }

  fileChangeEvent(event: any): void {
    this.toggleCropperModal();
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    var filename = this.fileInput.nativeElement.files[0].name;

    this.croppedImage = this.dataURLtoFile(event.base64, filename);

    this.formData = new FormData();

    this.formData.append(filename, this.croppedImage);
  }

  public saveImage() {
    this.uploadImage(this.formData).subscribe(
      (fileUrl) => {
        this.fileUploaded.emit({ file: fileUrl });
        this.toggleCropperModal();
      },
      (error) => {
        this.toastrService.error(`Não foi possível atualizar a imagem`);
        console.log({ error });
      }
    );
  }

  private dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  private uploadImage(params: any): Observable<any> {
    let _query = "";

    if (this.customSize) {
      _query = `?size=${this.customSize}`;
    }

    return this.http.post<any>(`${this.url}${this.path}${_query}`, params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("control-panel.token")}`,
        accept: "image/*",
      },
    });
  }

  private toggleCropperModal() {
    this.showCropperModal = !this.showCropperModal;
  }

  public imageLoaded() {
    this.showCropperModal = true;
  }

  public cropperReady() {
    // cropper ready
  }

  public loadImageFailed() {
    // show message
    this.showCropperModal = false;
    this.toastrService.error(`Não foi possível carregar a imagem`);
  }

  public cancelImageCrop() {
    this.showCropperModal = false;
    this.fileInput.nativeElement.value = "";
  }
}
