import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from 'src/app/services/api.service';

@Injectable()
export class FileUploadService {
  url: string;

  constructor(private http: HttpClient, private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1`;
              }

  uploadImage(path: string, formData: any): Observable<any> {
    return this.http.post(`${this.url}${path}`, formData, this.requestFormOptions());
  }

  downloadClientFile(type: string): Observable<any> {
    return this.http.post(`${this.url}/internal/client-sheets/content/${type}`, {}, this.apiService.requestBlobOptions());
  }


  requestFormOptions(): any {
    return { headers: this.requestFormHeaders() };
  }

  requestFormHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('control-panel.token')}`,
      'Content-Type': 'multipart/form-data'
    });
  }
}
