import { Component, Input, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Modal, SuccessModal, ConfirmModal } from './modal';

@Component({
  selector: 'ptc-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  isOpen: boolean;
  modal: Modal;
  @Input() id: string;

  private response: Subject<boolean>;
  response$: Observable<boolean>;

  constructor() {
    this.response = new Subject<boolean>();
    this.response$ = this.response.asObservable();
   }

  ngOnInit(): void {
    switch (this.id) {
      case 'success':
        this.modal = new SuccessModal();
        break;
      case 'confirm':
        this.modal = new ConfirmModal();
        break;
    }
  }

  choose(choice: boolean): void {
    this.response.next(choice);
    this.toggle();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  setMessage(message: string): void {
    this.modal.setMessage(message);
  }
}

