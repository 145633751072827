import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NgxPaginationModule } from "ngx-pagination";
import { NgxUploaderModule } from "ngx-uploader";
import { NgxFlowModule, FlowInjectionToken } from "@flowjs/ngx-flow";
import Flow from "@flowjs/flow.js";

import { SidebarComponent } from "./sidebar/sidebar.component";
import { HeaderComponent } from "./header/header.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { LinkViewComponent } from "./link-view/link-view.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { FilterComponent } from "./filter/filter.component";
import { ModalComponent } from "./modal/modal.component";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { DialogComponent } from "./dialog/dialog.component";
import { UsersRegisterComponent } from "./users-register/users-register.component";
import { TextMaskModule } from "angular2-text-mask";
import { VideoContainerComponent } from "./video-container/video-container.component";
import { ApprovalWidgetComponent } from "./approval-widget/approval-widget.component";
import { LaddaModule } from "angular2-ladda";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImageUploadCropperComponent } from "./image-cropper-upload/image-cropper-upload.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    NgxPaginationModule,
    NgxUploaderModule,
    ImageCropperModule,
    NgxFlowModule,
    LaddaModule,
  ],
  entryComponents: [LinkViewComponent],
  providers: [
    {
      provide: FlowInjectionToken,
      useValue: Flow,
    },
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    LinkViewComponent,
    PaginationComponent,
    FilterComponent,
    ModalComponent,
    ImageUploadComponent,
    DialogComponent,
    UsersRegisterComponent,
    VideoContainerComponent,
    ApprovalWidgetComponent,
    ImageUploadCropperComponent,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    PaginationComponent,
    FilterComponent,
    ModalComponent,
    ImageUploadComponent,
    DialogComponent,
    UsersRegisterComponent,
    VideoContainerComponent,
    ApprovalWidgetComponent,
    ImageUploadCropperComponent,
  ],
})
export class AppSharedModule {}
