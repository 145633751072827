import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ApiService {
  constructor() {}

  requestOptions(params: any = {}): any {
    return {
      headers: this.requestHeaders(),
      params: Object.assign({}, params)
    };
  }

  requestHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('control-panel.token')}`,
      'Content-Type': 'application/json'
    });
  }

  requestBlobOptions(): any {
    return { headers: this.requestHeaders(), responseType: 'blob' as 'json' };
  }

}
