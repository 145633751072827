import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ptc-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input() data: any;
  @Input() searchText: string;
  @Output() search: EventEmitter<any>;
  @Output() filter: EventEmitter<any>;
  @Output() select: EventEmitter<any>;
  selectedOption = '';

  constructor() {
    this.search = new EventEmitter<any>();
    this.filter = new EventEmitter<any>();
    this.select = new EventEmitter<any>();
    this.selectedOption = null;
  }

  searchFor(): void {
    this.search.emit(this.searchText || undefined);
  }

  filterFor(event: any): void {
    this.filter.emit(event);
  }

  changeSelect(selectedIndex) {
    this.select.emit({ key: this.data[selectedIndex].key, value: this.data[selectedIndex].selected.key });
  }
}
