import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/v1/internal/auth`, {
      email,
      password
    });
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/v1/internal/auth/forgot-password`,
      { email }
    );
  }

  resetPassword(value: any): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/internal/auth/password-reset`,
      value
    );
  }

  setUser(token: string, user: any): Promise<boolean> {
    return new Promise(resolve => {
      localStorage.setItem('control-panel.token', token);
      localStorage.setItem('control-panel.user', JSON.stringify(user));
      resolve(true);
    });
  }

  logout(): void {
    localStorage.removeItem('control-panel.token');
    localStorage.removeItem('control-panel.user');
    this.router.navigate(['/auth', 'login']);
  }

  isLoggedIn(): boolean {
    if (!localStorage.getItem('control-panel.token')) {
      return false;
    }

    const jwt = new JwtHelperService();
    jwt.decodeToken(localStorage.getItem('control-panel.token'));
    return (
      localStorage.getItem('control-panel.user') &&
      !jwt.isTokenExpired(localStorage.getItem('control-panel.token'))
    );
  }
}
