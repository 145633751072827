export class Modal {
  title: string;
  message: string;
  textBtTrue: string;
  textBtFalse?: string;

  constructor() { }

  setMessage(message: string): void {
    this.message = message;
  }
}

export class SuccessModal extends Modal {
  constructor() {
    super();
    this.title = `Sucesso!`;
    this.message = `Success!`;
    this.textBtTrue = `OK`;
  }
}

export class ConfirmModal extends Modal {
  constructor() {
    super();
    this.title = `Você tem certeza?`;
    this.message = ``;
    this.textBtTrue = `Confirmar`;
    this.textBtFalse = `Cancelar`;
  }
}

export class CustomModal extends Modal {
  constructor(title: string, message: string, textBtTrue: string, textBtFalse: string) {
    super();
    this.title = title;
    this.message = message;
    this.textBtTrue = textBtTrue;
    this.textBtFalse = textBtFalse;
  }
}

