import { Injectable } from '@angular/core';

@Injectable()
export class ToggleMenuService {
  opened: boolean;

  constructor() {
    this.opened = false;
  }

  toggle() {
    this.opened = !this.opened;
  }
}
