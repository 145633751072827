import { Injectable } from '@angular/core';

@Injectable()
export class MaskService {

  cep(): any {
    return [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }

  cpf(): any {
    return [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/,  '-', /\d/, /\d/];
  }

  cnpj(): any {
    return [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/,'/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  }

  date(): any {
    return [/[0-9]/, /\d/, '\/', /\d/, /\d/, '\/', /\d/, /\d/, /\d/, /\d/];
  }

  hour(): any {
    return [/[0-2]/, /[0-9]/, ':', /[0-5]/, /\d/];
  }

  cel(): any {
    return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  tel(): any {
    return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  telCel(): any {
    return {
      mask: (value) => {
        if (value.length <= 14) {
          return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        } else {
          return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        }
      },
      guide: false
    };
  }

}
